import styled from 'styled-components';
import classNames from 'classnames';

export const GameIconStyle = styled.div.attrs((props) => ({
	className: classNames('game-icon', props.className),
}))`
	display: inline-block;
	font-size: 16px;
	position: relative;
	line-height: 0;

	img,
	svg {
		height: 16px;
	}
`;

export const StyledFlashIcon = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.gold};
	border-radius: 100%;
	bottom: calc(100% - 7px);
	color: ${(props) => props.theme.colors.inputBackgroundSolid};
	display: flex;
	height: 14px;
	justify-content: center;
	left: 10px;
	position: absolute;
	width: 14px;

	svg {
		height: 10px;
	}
`;
