import {
	faBan,
	faCheckCircle,
	faHourglassHalf,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Tooltip } from '@legacyApp/client/components/tooltip';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { AwesomeIcon } from '@common/components/icons/AwesomeIcon';
import { AffiliateCampaignNameContainer } from '@legacyApp/client/components/affiliate/affiliateCampaignName';
import { UserLogin } from '@common/components/user/UserLogin/UserLogin';
import { capitalize } from '@legacyApp/client/modules/app/appService';
import {
	formatDate,
	formatToDate,
	formatToFullDate,
	formatToFullDateNoSeconds,
	formatToTime,
	getTimestamp,
} from '@legacyApp/client/modules/app/timeService';
import { Rounded } from '@common/components/number/Rounded';
import { RoundedWithCurrency } from '@common/components/number/RoundedWithCurrency';
import { StyledProfit } from '@common/components/number/StyledProfit';
import { BetAmount } from '@common/components/number/BetAmount';
import { currenciesSettings } from '@common/controllers/currency/currenciesSettings';
import { BalanceAmount } from '@common/components/number/balanceAmount';
import { RaceBadge } from '@common/components/user/UserLogin/RaceBadge';
import { TokenSymbol } from '@legacyApp/client/components/currencySymbol/tokenSymbol';
import { scientificToDecimal } from '@legacyApp/methods/math/scientificToDecimal';
import { config } from '@legacyApp/client/config';
import { CurrencySymbol } from '@legacyApp/client/components/currencySymbol/currencySymbol';
import { getExplorerUrl } from '@legacyApp/methods/wallet/getExplorerUrl';
import { Redirect } from '@common/components/default/Redirect/Redirect';
import { InputWrapperContainer } from '@legacyApp/client/components/input/inputWrapper.container';
import { UserActionsContainer } from '@legacyApp/client/components/userActions';
import { Loadable } from '@legacyApp/client/components/loader/loadable/loadable';
import { DIRECTION } from '@legacyApp/client/modules/app/sortService';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import { TableColumnConfig } from '../types';
import { PercentageRateColumn } from '../components/columns/PercentageRate.column';
import { PayoutValueColumn } from '../components/columns/PayoutValue.column';
import { TABLE_ID } from '../../transactions/constants/TableId';
import { BonusCode } from '../../vip/components/BonusCode/BonusCode';
import { racePositionString } from '../../race/methods/racePositionString';
import {
	PrizeColumn,
	RankColumn,
	WagerColumn,
} from '../../race/components/columns';
import BetIdSimple from '../../../common/components/bets/betId/BetIdSimple';
import { GameIcon } from '../../games/Game/components/Game.components/gameIcon';
import { BetSlipIcon } from '../../sports/components/BetSlipSection/BetSlipDetails/BetSlipIcon';

const StopReason = Loadable(
	{
		loader: () =>
			import(
				/* webpackChunkName: "StopReason" */ '../components/columns/StopReason.column'
			),
	},
	'./StopReason',
);

export const TABLE_COLUMNS: ReadonlyArray<TableColumnConfig> = [
	{
		name: '',
		id: '$.claimed',
		callback: () => {
			return (
				<Tooltip content={trans({ label: 'Claimed' })}>
					<AwesomeIcon icon={faCheckCircle} className="text-positive" />
				</Tooltip>
			);
		},
		types: [TABLE_ID.bonusHistoryClaimed],
	},
	{
		name: '',
		id: '$.claimed',
		callback: () => {
			return (
				<Tooltip content={trans({ label: 'Expired' })}>
					<AwesomeIcon icon={faBan} className="text-negative" />
				</Tooltip>
			);
		},
		types: [TABLE_ID.bonusHistoryExpired],
	},
	{
		name: '',
		id: '$.game.name',
		callback: (value, element) => {
			if (value === 'sports') {
				return <BetSlipIcon list={element?.betslip_events} />;
			}
			return <GameIcon flash={element.flash} name={value} />;
		},
		types: [
			TABLE_ID.allBets,
			TABLE_ID.highRolls,
			TABLE_ID.myBets,
			TABLE_ID.sessions,
		],
	},
	{
		name: 'Game',
		id: '$.uuid',
		callback: (value, element) => {
			return !value ? (
				'-'
			) : (
				<BetIdSimple
					onlyName
					type={'session'}
					game={element?.game?.name || element?.game}
					table
					id={String(value)}
					data={element}
				/>
			);
		},
		types: [TABLE_ID.sessions],
	},
	{
		name: 'User',
		id: '$.user.hidden_login',
		types: [TABLE_ID.affiliateCampaignUsers],
	},
	{
		name: 'Name',
		id: '$.name',
		callback: (value, element) => {
			return (
				<AffiliateCampaignNameContainer
					name={String(value)}
					id={element.id}
					data={element}
				/>
			);
		},
		types: [TABLE_ID.affiliateCampaigns, TABLE_ID.affiliateAnalytics],
	},
	{
		name: 'Type',
		id: '$.type_label',
		callback: (value) => {
			return (
				<span className="text-capitalize">
					{trans({ label: String(value) })}
				</span>
			);
		},
		types: [TABLE_ID.otherTransactions],
	},
	{
		name: 'Rank',
		id: '$.position',
		callback: (value, element) => {
			return <RankColumn value={Number(value)} element={element} />;
		},
		types: [TABLE_ID.raceResults, TABLE_ID.leaderboard],
	},
	{
		name: 'Game',
		id: '$.hash',
		callback: (value, element) => {
			return !value ? (
				'-'
			) : (
				<BetIdSimple
					onlyName
					type={element.game?.name}
					id={String(value)}
					table
					data={element}
					game={null}
				/>
			);
		},
		types: [TABLE_ID.allBets, TABLE_ID.highRolls, TABLE_ID.myBets],
	},
	{
		name: 'Bet ID',
		id: '$.hash',
		callback: (value, element) => {
			return !value ? (
				'-'
			) : (
				<BetIdSimple
					type={element.game?.name}
					id={String(value)}
					table
					data={element}
					game={null}
				/>
			);
		},
		types: [TABLE_ID.sessionHistory],
	},
	{
		name: 'User',
		id: '$.user.login',
		callback: (value, element) => {
			if (!element.user) {
				return null;
			}
			return (
				<UserLogin
					fullWidth={true}
					user={{
						...element.user,
						login: value,
						is_admin: null,
						is_moderator: null,
					}}
				/>
			);
		},
		parseHidden: true,
		types: [TABLE_ID.allBets, TABLE_ID.highRolls, TABLE_ID.friendsIgnored],
	},
	{
		name: 'User',
		id: '$.user.login',
		callback: (value, element) => {
			if (!element.user) {
				return null;
			}
			return (
				<UserLogin
					fullWidth={true}
					user={{
						...element.user,
						login: value,
						is_admin: null,
						is_moderator: null,
						race_badge: null,
					}}
				/>
			);
		},
		parseHidden: true,
		types: [TABLE_ID.raceResults, TABLE_ID.leaderboard],
	},
	{
		name: '',
		id: '$.state',
		callback: (value) => {
			if (!value) {
				return '-';
			}
			let statusText = capitalize(value);
			let icon = null;
			let iconClass = '';
			if (value === 'accepted') {
				icon = faCheckCircle;
				iconClass = 'text-positive';
			}
			if (['processing', 'manual processing'].indexOf(String(value)) > -1) {
				icon = faHourglassHalf;
				statusText = capitalize('processing');
			}
			if (value === 'declined') {
				icon = faBan;
				iconClass = 'text-negative';
			}
			return icon ? (
				<Tooltip content={trans({ label: statusText })}>
					<AwesomeIcon icon={icon} className={iconClass} />
				</Tooltip>
			) : (
				statusText
			);
		},
		types: [TABLE_ID.depositHistory, TABLE_ID.withdrawHistory],
	},
	{
		name: 'Date',
		id: '$.published_at',
		callback: (value, element) => {
			if (!value && element.created_at) {
				value = roundNumber(getTimestamp(element.created_at) / 1000, 0);
			}
			if (!value) {
				return '-';
			}
			return formatToDate(Number(value) * 1000);
		},
		types: [
			TABLE_ID.depositHistory,
			TABLE_ID.withdrawHistory,
			TABLE_ID.affiliateCampaigns,
			TABLE_ID.affiliateCampaignUsers,
		],
	},
	{
		name: 'Date',
		id: '$.published_at',
		callback: (value, element) => {
			if (!value && element.created_at) {
				value = roundNumber(getTimestamp(element.created_at) / 1000, 0);
			}
			if (!value) {
				return '-';
			}
			return formatDate(Number(value) * 1000, 'YYYY-MM');
		},
		types: [TABLE_ID.affiliateCampaignHistory],
	},
	{
		name: 'Position',
		id: '$.race_position',
		callback: (value) => {
			if (!value) {
				return '-';
			}
			return (
				<span className="flex-vertical-center">
					<RaceBadge
						data={{
							position: value,
						}}
					/>{' '}
					{racePositionString(value)}
				</span>
			);
		},
		types: [TABLE_ID.lastRacesResults],
	},
	{
		name: 'Prize',
		id: '$.prize',
		callback: (value, element) => {
			if (!element.currency) {
				element.currency = 'btc';
			}
			return (
				<BetAmount
					isCurrencyInFront
					isProfitColor
					state={element.state}
					value={Number(value)}
					currency={element.currency}
				/>
			);
		},
		types: [TABLE_ID.lastRacesResults],
	},
	{
		name: 'Date',
		id: '$.published_at',
		callback: (value, element) => {
			if (!value && element.created_at) {
				value = roundNumber(getTimestamp(element.created_at) / 1000, 0);
			}
			if (!value) {
				return '-';
			}
			return formatDate(value * 1000, 'YYYY-MM-DD HH:mm');
		},
		types: [
			TABLE_ID.lastRacesResults,
			TABLE_ID.affiliateCashoutHistory,
			TABLE_ID.vaultHistory,
		],
	},
	{
		name: 'Wagered',
		id: '$.waggered',
		callback: (value, element) => {
			return <WagerColumn value={value} element={element} />;
		},
		types: [TABLE_ID.raceResults],
	},
	{
		name: 'Profit',
		id: '$.profit',
		callback: (value, el) => {
			return (
				<StyledProfit $value={Number(value)} as={'div'}>
					<RoundedWithCurrency value={Number(value)} currency={el.currency} />
				</StyledProfit>
			);
		},
		types: [TABLE_ID.leaderboard, TABLE_ID.sessions],
	},
	{
		name: 'Wagered',
		id: '$.wagered',
		callback: (value, el) => {
			return (
				<div>
					<RoundedWithCurrency value={Number(value)} currency={el.currency} />
				</div>
			);
		},
		types: [TABLE_ID.leaderboard, TABLE_ID.sessions],
	},
	{
		name: 'Bets',
		id: '$.bets',
		callback: (value) => <Rounded value={Number(value)} isInt={true} />,
		types: [TABLE_ID.leaderboard, TABLE_ID.sessions],
	},
	{
		name: 'Tips dealt',
		id: '$.tips',
		callback: (value, el) => {
			return (
				<div>
					<RoundedWithCurrency value={Number(value)} currency={el.currency} />
				</div>
			);
		},
		types: [TABLE_ID.leaderboard],
	},
	{
		name: 'Profit',
		id: '$.profit',
		callback: (value, element) => (
			<BetAmount
				isCurrencyInFront
				isProfitColor
				state={element.state}
				value={Number(value)}
				currency={element.currency}
			/>
		),
		types: [
			TABLE_ID.allBets,
			TABLE_ID.highRolls,
			TABLE_ID.myBets,
			TABLE_ID.sessionHistory,
		],
	},
	{
		name: 'Payout',
		id: '$.multiplier',
		callback: (value, element) => {
			return (
				<PayoutValueColumn game={element.game?.name} amount={Number(value)} />
			);
		},
		types: [
			TABLE_ID.allBets,
			TABLE_ID.highRolls,
			TABLE_ID.myBets,
			TABLE_ID.sessionHistory,
		],
	},
	{
		name: 'Code',
		id: '$.code',
		callback: (value) => <BonusCode noCopy code={value} />,
		types: [TABLE_ID.bonusHistoryExpired, TABLE_ID.bonusHistoryClaimed],
	},
	{
		name: 'Amount',
		id: '$.amount',
		callback: (value, element) => {
			if (!Number(element?.amount)) {
				return <span className="text-gold">{trans({ label: 'Bonus' })}</span>;
			}
			return (
				<BetAmount
					isCurrencyInFront
					value={Number(value)}
					currency={element.currency}
				/>
			);
		},
		types: [TABLE_ID.allBets, TABLE_ID.highRolls, TABLE_ID.myBets],
	},
	{
		name: 'Amount',
		id: '$.amount',
		callback: (value, element) => {
			return (
				<BetAmount
					isCurrencyInFront
					value={Number(value)}
					currency={element.currency}
				/>
			);
		},
		types: [
			TABLE_ID.sessionHistory,
			TABLE_ID.otherTransactions,
			TABLE_ID.vaultHistory,
			TABLE_ID.bonusHistoryExpired,
			TABLE_ID.bonusHistoryClaimed,
		],
	},
	{
		name: 'Time',
		id: '$.published_at',
		callback: (value, el) => {
			if (el.accounted_at) {
				value = el.accounted_at;
			}
			if (!value) {
				return '-';
			}
			value = value * 1000;
			return formatToTime(value, true);
		},
		types: [
			TABLE_ID.allBets,
			TABLE_ID.highRolls,
			TABLE_ID.myBets,
			TABLE_ID.sessionHistory,
		],
	},
	{
		name: 'Details',
		id: '$.list',
		callback: (value: Array<any>) => {
			if (!value || !value.length) {
				return '-';
			}
			return (
				<span>
					{value
						.sort((a, b) =>
							currenciesSettings.sortCurrency(
								DIRECTION.ASC,
								a.currency,
								b.currency,
							),
						)
						.map((el) => (
							<BalanceAmount
								key={el.currency}
								value={el.amount || 0}
								currency={el.currency}
								simpleFormat
								currencyInFront
							/>
						))}
				</span>
			);
		},
		types: [TABLE_ID.affiliateCashoutHistory],
	},
	{
		name: 'Share',
		id: '$.commission_rate',
		callback: (value: string) => {
			return <PercentageRateColumn amount={Number(value)} />;
		},
		types: [TABLE_ID.affiliateAnalytics],
	},
	{
		name: 'Hits',
		id: '$.global.hits',
		types: [TABLE_ID.affiliateAnalytics, TABLE_ID.affiliateCampaignHistory],
	},
	{
		name: 'Referrals',
		id: '$.global.referrals',
		types: [TABLE_ID.affiliateAnalytics, TABLE_ID.affiliateCampaignHistory],
	},
	{
		name: 'FTDs',
		id: '$.global.ftds',
		types: [
			TABLE_ID.affiliateAnalytics,
			TABLE_ID.affiliateAnalytics,
			TABLE_ID.affiliateCampaignHistory,
		],
	},
	{
		name: 'Deposits',
		id: '$.global.deposits',
		types: [
			TABLE_ID.affiliateAnalytics,
			TABLE_ID.affiliateCampaignHistory,
			TABLE_ID.affiliateCampaignUsers,
			TABLE_ID.affiliateAnalytics,
		],
	},
	{
		name: 'Wagered',
		id: '$.global.waggered',
		callback: (value) => {
			if (!value || !value.length) {
				return '-';
			}
			value = value.filter((el) => Number(el.amount));
			if (!value.length) {
				return '-';
			}
			return (
				<span>
					{value
						.sort((a, b) =>
							currenciesSettings.sortCurrency(
								DIRECTION.ASC,
								a.currency,
								b.currency,
							),
						)
						.map((el) => (
							<BalanceAmount
								key={el.currency}
								value={el.amount || 0}
								currency={el.currency}
								simpleFormat
								currencyInFront
							/>
						))}
				</span>
			);
		},
		types: [TABLE_ID.affiliateCampaignUsers],
	},
	{
		name: 'Amount',
		id: '$.amount',
		callback: (value, element) => {
			value = Number(value);
			if (!value) {
				return '-';
			}
			return (
				<div>
					<TokenSymbol token={element.token || element.currency} />{' '}
					{value % 1 === 0 ? value.toFixed(4) : scientificToDecimal(value)}
				</div>
			);
		},
		types: [TABLE_ID.depositHistory, TABLE_ID.withdrawHistory],
	},
	{
		name: 'Available',
		id: '$.available',
		callback: (value, element) => (
			<BalanceAmount
				color
				colorDecimals={config.decimals.bet}
				value={value}
				currency={element.currency}
				fullDecimals={12}
				currencyInFront={true}
			/>
		),
		types: [TABLE_ID.affiliateFunds],
	},
	{
		name: 'Available',
		id: '$.available',
		callback: (value, element) => {
			value = Number(value);
			if (!value) {
				return '-';
			}
			return (
				<div>
					<CurrencySymbol currency={element.currency} />{' '}
					{value % 1 === 0 ? value.toFixed(4) : scientificToDecimal(value)}
				</div>
			);
		},
		types: [TABLE_ID.affiliateAnalytics],
	},
	{
		name: 'Cashed out',
		id: '$.withdrawn',
		callback: (value, element) => (
			<BalanceAmount
				value={value}
				currency={element.currency}
				fullDecimals={12}
				currencyInFront={true}
			/>
		),
		types: [TABLE_ID.affiliateFunds],
	},
	{
		name: 'Total Commission',
		id: '$.total_commission',
		callback: (value, element) => (
			<BalanceAmount
				value={value}
				currency={element.currency}
				fullDecimals={12}
				currencyInFront={true}
			/>
		),
		types: [TABLE_ID.affiliateFunds],
	},
	{
		name: 'Commission',
		id: '$.global.commission_normalized',
		callback: (value, element) => {
			value = Number(value);
			if (!value) {
				return '-';
			}
			return (
				<div>
					<CurrencySymbol currency={element.currency || 'usd'} />{' '}
					{value % 1 === 0 ? value.toFixed(4) : scientificToDecimal(value)}
				</div>
			);
		},
		types: [TABLE_ID.affiliateAnalytics, TABLE_ID.affiliateCampaigns],
	},
	{
		name: 'TxID',
		id: '$.hash',
		// @ts-expect-error invalid return type
		callback: (value: string, element, data) => {
			if (!value || value === element.uuid) {
				return '-';
			}
			const url = getExplorerUrl({
				network: element.network,
				txid: value,
				date: element.published_at,
				object: { element, tableId: 'deposit - hash', data },
			});
			if (!url) {
				return value;
			}
			return (
				<Redirect href={url} rel="noopener noreferrer" target="_blank">
					{value}
				</Redirect>
			);
		},
		types: [TABLE_ID.depositHistory],
	},
	{
		name: 'TxID',
		id: '$.txid',
		// @ts-expect-error invalid return type
		callback: (value: string, element, data) => {
			if (!value || value === element.uuid) {
				return '-';
			}
			const url = getExplorerUrl({
				network: element.network,
				txid: value,
				date: element.published_at,
				object: { element, tableId: 'withdraw - txid', data },
			});
			if (!url) {
				return value;
			}
			return (
				<Redirect href={url} rel="noopener noreferrer" target="_blank">
					{value}
				</Redirect>
			);
		},
		types: [TABLE_ID.withdrawHistory],
	},
	{
		name: 'Address',
		id: '$.address',
		callback: (value) => {
			if (!value) {
				return '-';
			}
			return (
				<InputWrapperContainer
					name={'address'}
					value={value}
					isCopy={true}
					isDisabled={true}
				/>
			);
		},
		types: [TABLE_ID.withdrawHistory],
	},
	{
		name: 'Prize',
		id: '$.prize',
		callback: (value, element) => {
			return <PrizeColumn value={Number(value)} element={element} />;
		},
		types: [TABLE_ID.raceResults],
	},
	{
		name: 'Stop reason',
		id: '$.stop_reason',
		callback: (value) => <StopReason value={value} />,
		types: [TABLE_ID.sessions],
	},
	{
		name: 'Date',
		id: '$.published_at',
		callback: (value, element) => {
			if (!value && element.created_at) {
				value = roundNumber(getTimestamp(element.created_at) / 1000, 0);
			}
			if (!value) {
				return '-';
			}
			return formatToFullDate(value * 1000);
		},
		types: [TABLE_ID.otherTransactions, TABLE_ID.sessions],
	},
	{
		name: 'Claimed at',
		id: '$.claimed_at',
		callback: (value) => {
			if (!value) {
				return '-';
			}
			return formatDate(getTimestamp(value), 'YYYY-MM-DD HH:mm');
		},
		types: [TABLE_ID.bonusHistoryClaimed],
	},
	{
		name: 'Expired at',
		id: '$.expire_at',
		callback: (value) => {
			return (
				<span
					className="text-style-sm-regular"
					style={{ color: 'var(--color-dark-200)' }}
				>
					{value ? formatToFullDateNoSeconds(getTimestamp(value)) : '-'}
				</span>
			);
		},
		types: [TABLE_ID.bonusHistoryExpired],
	},
	{
		name: 'Action',
		id: '$.state',
		callback: (value, element) => {
			return <UserActionsContainer user={element.user} status={value} />;
		},
		types: [TABLE_ID.friendsIgnored],
	},
];
